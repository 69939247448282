.navbar-toggler-default,
.navbar-toggler-toggled {
  pointer-events: none;
}

.navbar-container {
  background: var(--main-color-bg);
}

@media (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 53px;
    left: 0;
    right: 0;
    height: 0;
    transition: height 0.5s ease;
    overflow: hidden;
  }
  .navbar-collapse.show-menu {
    height: var(--custom-navbar-height);
  }

  .custom-navbar {
    li {
      padding: 2px 0;
    }
    margin-top: 10px;
  }
}
