.schedule-head {
  background-color: transparent !important;
  th {
    color: #000 !important;
    font-size: 1.6rem;
  }
}

.schedule-date-number {
  font-size: 130%;
}

.schedule-head-row {
  td {
    background-color: var(--main-color-bg) !important;
    color: var(--bs-white) !important;
  }
}

.schedule-follow-by {
  padding: 0.3rem 0.5rem !important;
  background-color: #e8e8e8;
}
