.player-scoring,
.player-favorites {
  cursor: pointer;
}

.player-scoring-td {
  overflow: auto;
  padding: 0 !important;
}

.scoring-table-header {
  color: var(--bs-white);
}

.scoring-table {
  // font-size: 90%;
  margin-bottom: 0;
}

.rounds-scoring {
  font-weight: 600;
}

.scoring-table th {
  padding: 5px;
  font-weight: bold;
}

.scoring-table-body {
  padding: 0;
  margin: 0;
  // font-weight: 600;
}

.scoring-table-body > tr {
  &:nth-child(odd) {
    background-color: var(--bs-white);
  }
}

.table-head-scroing {
  color: #fff;
  font-weight: 700;
  padding: 5px;
  font-size: 80%;
  // text-transform: uppercase;
  border-style: hidden;
  height: 25px;
}

.table-head-scroing td:first-child {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  background: var(--main-color-bg);
}

.scoring-table-body tr td:first-child {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  @media (max-width: 600px) {
    background: #fff;
  }
}
