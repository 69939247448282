.site-locator {
  .scroll-to-top {
    display: none;
  }
}

.scroll-to-top {
  position: fixed;
  border-radius: 50%;
  background-color: var(--bs-white);
  cursor: pointer;
  opacity: 0;
  width: 48px;
  height: 48px;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  transform: translateY(100px);
  transition: transform 0.5s ease;
  border: 2px solid var(--main-color-bg, --bs-white);

  svg {
    fill: var(--main-color-bg, --bs-white);
    width: 34px;
    height: 34px;
  }
}

.show-scroll-to-top {
  opacity: 0.8;
  transform: translateY(0);
  &:hover {
    opacity: 1;
  }
}
