.table-tennis {
  table-layout: auto;
}

.td-defeats {
  background: var(--bs-white);
  width: 10px;
}

.text-rotation {
  color: #1f2223;
  display: block;
  font-size: 11px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: capitalize;
  transform: rotate(-90deg);
}
