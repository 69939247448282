.container-lg {
  max-width: 1140px;
}

.navbar {
  position: relative;
  z-index: 1001;
  padding: 0;
  background-color: var(--bs-white);
  .container {
    max-width: 100%;
  }
}

.navbar-brand-logo {
  max-height: 40px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  padding: 0;
  img {
    max-height: 54px;
    @media (max-width: 370px) {
      max-width: 100px;
    }
    @media (max-width: 991px) {
      max-height: 54px;
    }
  }
}

.navbar-brand-wrapper {
  display: flex;
  align-items: center;
  height: 76px;
  width: 100%;
}

.navbar-sponsor-logo {
  img {
    max-width: 100%;
    max-height: var(--header-height, 76px);
    @media (max-width: 991px) {
      // max-height: 54px;
    }
  }
}

.links-style {
  font-family: "Inter", sans-serif !important;
  letter-spacing: 1px;
  &.active:after {
    background-color: #377dff;
    @media (min-width: 992px) {
      width: 100%;
    }
  }

  color: #377dff;
  margin-right: 5px;
  text-decoration: none;

  &:after {
    background-color: initial;
    content: "";
    display: block;
    height: 2px;
    margin: auto;
    transition: width 0.5s ease, background-color 0.5s ease;
    width: 0;
  }

  @media (min-width: 992px) {
    &:hover::after {
      background-color: #377dff;
      width: 100%;
    }
  }
}

.navbar-toggler {
  svg {
    fill: var(--bs-white);
    width: 40px;
    height: 40px;
  }
}

.navbar-toggler:focus {
  box-shadow: none;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  transition: top 0.3s ease;
}

[aria-expanded="true"] {
  .navbar-toggler-default {
    display: none;
  }
}

[aria-expanded="false"] {
  .navbar-toggler-toggled {
    display: none;
  }
}

.test {
  background: red;
}

.parner-watch {
  max-height: 76px;
  @media (min-width: 992px) {
    border-bottom: 1px solid #1b6039;
  }
  @media (max-width: 992px) {
    display: none;
  }
}

.watch-partner-small {
  position: relative;
  display: block;
  width: 100%;
  height: 78px;
  z-index: 99;
  align-items: center;
  padding: 0;
  // top: 76px;
  text-align: center;
  background: #1b6039;
  overflow: hidden;

  iframe {
    // max-height: 78px;
    overflow: hidden;
  }

  @media (min-width: 992px) {
    display: none;
  }
}
