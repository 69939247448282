.custom-info {
  // position: absolute;
  // top: 53px;
  // left: 0;
  color: #fff;
  width: 100%;
  background-color: #8a0719;
  display: none;
}

.custon-info-message {
  max-width: 1140px;
  padding: 3px 10px;
  margin: auto;
  font-size: 90%;
}

[data-info="true"] {
  .leaflet-control-zoom {
    margin-top: 50px !important;
  }

  .custom-info {
    display: block;
  }
}

.site-locator {
  .custom-info {
    position: absolute;
    @media (max-width: 991px) {
      position: relative;
    }
    z-index: 999;
  }
}

.message-partner {
  position: relative;
  width: 100%;
  z-index: 999;
  top: var(--header-height, 0px);
}

.site-locator {
  .message-partner {
    position: fixed;
  }
}
