.footer-color {
  background-color: var(--main-color-bg) !important;
}

@media (max-width: 480px) {
  footer {
    font-size: 75%;
  }
}

footer a {
  position: relative;
  text-decoration: none;
  padding-bottom: 2px;
}

footer a:hover {
  color: #f2f2f2;
  border-bottom: 1px solid #fff;
}

footer a::after {
  position: absolute;
  content: "/";
  color: #c7c7c7;
  right: -18px;
  top: -2px;
}

footer ul li:last-child a::after {
  display: none;
}

.list-separator .list-inline-item:not(:last-child) {
  padding-right: 1rem;
}
