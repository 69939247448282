.scoring-squads {
  font-size: 1.4rem;
  @media (max-width: 710px) {
    font-size: 1rem;
  }
  .squad-eur {
    background: #003c82;
  }
  .squad-usa {
    background: #c81414;
  }
}
.table-head-lead {
  .team-eur {
    background: #003c82;
  }
  .team-usa {
    background: #c81414;
  }
}

.player-lead {
  svg {
    width: 25px;
    height: 25px;
    fill: #c81414;
  }
}

div[data-match-id] {
  .round-name + .round-name {
    margin-top: 20px;
  }

  &:nth-child(odd) {
    background-color: #f5f5f5;
  }
  cursor: pointer;

  &:hover {
    .full-name {
      color: #c81414;
    }
  }
}

@media (max-width: 768px) {
  .points-section {
    .row {
      font-size: 80%;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@media (max-width: 576px) {
  .points-section {
    svg {
      width: 15px;
      height: 15px;
    }
  }
}

@media (max-width: 992px) {
  .players-eur {
    order: 1;
  }
  .players-usa {
    order: 2;
  }

  .leader-points {
    order: 3;
    flex-basis: auto;
    margin-top: 20px;
  }
}

.player-details-wrapper {
  // border: 2px solid gray;
  @media (min-width: 576px) {
    flex-direction: row;
    display: flex;
  }

  @media (min-width: 992px) {
    flex-direction: column;
  }
}

.player-info-wrapper {
  margin: 10px 0;
  border-bottom: 1px solid var(--bs-gray-300);

  line-height: 100%;
  img {
    max-width: 100px;
  }
  .country {
    color: #adb5bd;
    font-size: 80%;
  }
}

.player-image img {
  display: block;
  margin: auto;
}

.color-standing {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80%;
}

// -----------------

.color-usa {
  background: #c81414;
}

.color-eur {
  background: #003c82;
}

// -----------------
.leader-points-table {
  // border: 1px solid red;
}

.table-points-wrapper {
  border: 1px solid #e7e7e7;

  @media (max-width: 607px) {
    overflow-x: auto;
  }
}

.points-table {
  margin: 0 !important;
  font-size: 80%;
  caption-side: bottom;
  border-collapse: collapse;
  background: #fafeff;
  thead tr:first-child {
    background-color: var(--main-color-bg, #08285d);
    color: #fff;
  }

  tr td:first-child,
  td:first-child {
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9;
  }

  > :not(:first-child) {
    border-top: none !important;
  }

  .dark-color td:first-child {
    background-color: var(--main-color-bg, #08285d);
  }
  .light-color td:first-child {
    background: #fafeff;
  }
}

// .points-section-row {
//   display: grid;
//   grid-template-rows: 0fr;
//   overflow: hidden;
//   transition: grid-template-rows 200ms;
//   .row {
//     min-height: 0;
//     background: black;
//     color: white;
//   }
// }

.show-players-points {
  color: #c81414;
  .arrow-icon {
    rotate: 180deg;
  }

  // + .points-section-row {
  // grid-template-rows: 1fr;
  // }
}
