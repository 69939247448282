.section-sponsors {
  background-color: var(--main-color-bg, --bs-white);
  color: var(--bs-white);

  img {
    max-width: 200px;
  }

  // @media (max-width: 991px) {
  //   img {
  //     max-width: 150px;
  //   }
  // }

  // @media (max-width: 560px) {
  //   img {
  //     max-width: 200px;
  //   }
  // }
}
